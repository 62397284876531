import { useState, useEffect } from 'react';

import { Layout, Loading } from 'element-react';
import { useTranslation } from 'react-i18next';

import { API } from 'aws-amplify';
import { listCompanys, listProducts } from '../graphql/queries';
import userStore from '../store/user';
import RfqBackground from "../assets/imgs/bainub-RFQ.jpg";
import RfqLaptop from "../assets/imgs/rfq-laptop.png";
import Rfq01 from "../assets/imgs/rfq-01.jpg";
import Rfq02 from "../assets/imgs/rfq-02.jpg";
import Rfq03 from "../assets/imgs/rfq-03.jpg";
import Rfq04 from "../assets/imgs/rfq-04.jpg";
import Rfq05 from "../assets/imgs/rfq-05.jpg";

import WelcomeBanner from '../components/WelcomeBanner'
import SuppliersSwiper from '../components/SuppliersSwiper'
import ProductsSwiper from '../components/ProductsSwiper'
import AboutUsSwiper from '../components/AboutUsSwiper'
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api-graphql";
import RegisterSurvey from "./RegisterSurvery";
import surveyInfo from "../store/surveyStatus";
import PagerQuery from '../utils/PagerQuery';
import { Link } from "react-router-dom";
import {GET_PRODUTCS_URL, GET_SYSTEM_CONFIG_URL} from '../utils/Constants';



function Main() {
    const { t } = useTranslation();
    const { user } = userStore();
    const [[loading , setLoading], [loadingSuppliers , setLoadingSuppliers], [loadingFeaturedProducts , setLoadingFeaturedProducts], [loadingRecentlyAddedProducts , setLoadingRecentlyAddedProducts], [suppliers , setSuppliers], [featuredProducts , setFeaturedProducts], [recentlyAddedProducts , setRecentlyAddedProducts]]  = [useState(false), useState(false), useState(false), useState(false), useState([]), useState([]), useState([])]
    const {surveyStatus, sessionSurveyStatus, setSessionSurveyStatus} = surveyInfo();
    const [surveyVisible, setSurveyVisible] = useState(false);
    const [userInfo, setUserInfo] = useState({});
    
    useEffect(function () {
        // setLoading(true)
        setLoadingSuppliers(true)
        loadSuppliers()
        setLoadingFeaturedProducts(true)
        loadFeaturedProducts()
        setLoadingRecentlyAddedProducts(true)
        //loadRecentlyAddedProducts()
        setSessionSurveyStatus(false);
        setUserInfo(user);
        if (!surveyStatus && !sessionSurveyStatus) {
            console.log("survey call")
            console.log(user)
            setTimeout(() => {setSurveyVisible(true)}, 3000);
            }}, [])

    async function loadSuppliers() {
        console.log("loading new suppliers ...");
        const input = { filter : { hidden : { eq : 0 } } } ;
        const pagerQuery = new PagerQuery(listCompanys, input, Object.keys({ listCompanys }));
        const suppliers = await pagerQuery.searchPublicData();
        //order supliers by createdAt desc
        suppliers.data.listCompanys.items.sort((a, b) => (a.createdAt > b.createdAt) ? -1 : 1)
        setSuppliers(suppliers.data.listCompanys.items.slice(0,16))
        setLoadingSuppliers(false)
    }

    async function loadFeaturedProducts() {
        console.log("loading featured products ...");
        //const featuredProducts = await API.graphql({ query: listProducts, variables: { filter: { featured: { eq: 1 }, status : { eq: 1} }, limit: 10 }, authMode: GRAPHQL_AUTH_MODE.AWS_IAM  })
        //const input = { filter: { status : { eq: 1} } };
        //const pagerQuery = new PagerQuery(listProducts, input, Object.keys({ listProducts }));
        //let featuredProducts = await pagerQuery.searchPublicData();

        const response = await fetch(GET_PRODUTCS_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                //'Authorization': token
            }
        });
        const data = await response.json();
        console.log("::::: FEATURED PRODUCTS :::::");
        console.log({data});

        const responseFeatured = await fetch(GET_SYSTEM_CONFIG_URL + "web.products.home.featured", {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                //'Authorization': token
            }
        });
        const dataFeatured = await responseFeatured.json();
        console.log("::::: FEATURED PRODUCTS SYSTEM CONFIG :::::");
        console.log({dataFeatured});
        var arrayFeatured = dataFeatured[0].value.split("|");

        //let recentlyAddedProducts = featuredProducts;
        //console.log(featuredProducts)
        let lastList = data;
        //lastList = lastList.filter((item) => item.featured === 1);
        lastList = lastList.filter((item) => arrayFeatured.includes(item.id));
        console.log({lastList})
        shuffleArray(lastList);
        if(lastList.length > 6){
            setFeaturedProducts(limitOneByCompany(lastList))
        }
        else{
            setFeaturedProducts(lastList)
        }
            
        
        //data.sort((a, b) => (a.createdAt > b.createdAt) ? -1 : 1)
        //const _finalList = limitOneByCompany(data);

        const responseRecent = await fetch(GET_SYSTEM_CONFIG_URL + "web.products.home.recent", {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                //'Authorization': token
            }
        });
        const dataRecent = await responseRecent.json();
        console.log("::::: Recent PRODUCTS SYSTEM CONFIG :::::");
        console.log({dataRecent});
        var arrayRecent = dataRecent[0].value.split("|");
        const listRecent = data.filter((item) => arrayRecent.includes(item.id));
        setRecentlyAddedProducts(listRecent);
    
        setLoadingFeaturedProducts(false)
        setLoadingRecentlyAddedProducts(false)
    }

    function limitOneByCompany(list){
        let _finalList = [];
        let _companyList = [];
        list.map((item) => {
            if(_companyList.length >= 6)
                return
            if(!_companyList.includes(item.company_id)){
                _companyList.push(item.company_id);
                _finalList.push(item);
            }
        })
        return _finalList;
    }


    function shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          // Swap array[i] and array[j]
          [array[i], array[j]] = [array[j], array[i]];
        }
      }

    async function loadRecentlyAddedProducts() {
        console.log("loading recently added products ...");
        //const recentlyAddedProducts = await API.graphql({ query: listProducts, variables: { limit: 10, filter: { status: { eq: 1 } } }, authMode: GRAPHQL_AUTH_MODE.AWS_IAM })
        // console.log(recentlyAddedProducts)
        const input = { filter: { status: { eq: 1 } } };
        const pagerQuery = new PagerQuery(listProducts, input, Object.keys({ listProducts }));
        const recentlyAddedProducts = await pagerQuery.searchPublicData();
        //order by createdAt
        recentlyAddedProducts.data.listProducts.items.sort((a, b) => (a.createdAt > b.createdAt) ? -1 : 1)
        setRecentlyAddedProducts(recentlyAddedProducts.data.listProducts.items.slice(0,6))
        setLoadingRecentlyAddedProducts(false)
    }

    if(loading)
        return <Loading text={t('general.loading')} loading={loading} fullscreen={true} />
    return(
        <>
            <Layout.Row justify="center" align="middle">
                { user.name ? <></> : <WelcomeBanner /> }
            </Layout.Row>

            <Layout.Row className='top-searches-padding'>
                <Layout.Col className="top-searches-padding" xs="24" sm="24" md="3" lg="3">
                TOP SEARCHES: 
                </Layout.Col>
                <Layout.Col className="top-searches-padding" xs="12" sm="12" md="3" lg="3">
                <div class="top-searches-box">Textiles</div>
                </Layout.Col>
                <Layout.Col className="top-searches-padding" xs="12" sm="12" md="3" lg="3">
                <div class="top-searches-box">Plastic Products</div>
                </Layout.Col>    
                <Layout.Col className="top-searches-padding" xs="12" sm="12" md="3" lg="3">
                <div class="top-searches-box">Honey</div>
                </Layout.Col>    
                <Layout.Col className="top-searches-padding" xs="12" sm="12" md="3" lg="3">
                <div class="top-searches-box">Machinery</div>
                </Layout.Col>
                <Layout.Col className="top-searches-padding" xs="12" sm="12" md="3" lg="3">
                <div class="top-searches-box">Tequila</div>
                </Layout.Col>  
                <Layout.Col className="top-searches-padding" xs="12" sm="12" md="3" lg="3">
                <div class="top-searches-box">Plastic Injection</div>
                </Layout.Col>  
                <Layout.Col className="top-searches-padding" xs="12" sm="12" md="3" lg="3">
                <div class="top-searches-box">Pet Clothing</div>
                </Layout.Col>
                <Layout.Col className="top-searches-padding" xs="12" sm="12" md="3" lg="3">
                <div class="top-searches-box">Wooden Crafts</div>
                </Layout.Col> 
                <Layout.Col className="top-searches-padding" xs="12" sm="12" md="3" lg="3">
                <div class="top-searches-box">Filters</div>
                </Layout.Col>                
            </Layout.Row>

            <Layout.Row justify="center" align="middle" className="m-t-xs productsRow responsivePadding">
                <Layout.Col span="24">
                    <h2 className="primary-color">{t('home-page.featured-products')}</h2>
                </Layout.Col>
                <ProductsSwiper
                    slidesPerView={5}
                    spaceBetween={10}
                    products={featuredProducts}
                    loading={loadingFeaturedProducts}
                    />
            </Layout.Row>

            <Layout.Row justify="center" align="middle" className="m-t-xs m-b-xl responsivePadding productsRow">
                <Layout.Col span="24">
                <h2 className="primary-color">{t('home-page.recently-added-products')}</h2>
                </Layout.Col>

                <ProductsSwiper
                    slidesPerView={5}
                    spaceBetween={10}
                    products={recentlyAddedProducts}
                    loading={loadingRecentlyAddedProducts}
                    />
            </Layout.Row>

            
			

            <Layout.Row className="rfq-section-1">
                <Layout.Row>
                <Layout.Col xs="24" sm="24" md="24" lg="24" > 
                    <Layout.Row>
                    <h2>Active Requirements:</h2>
                    <h3>We have companies in the US and Canada actively looking for:</h3>
                    </Layout.Row>   
                    <Layout.Row>
                        <Layout.Col xs="12" sm="12" md="5" lg="5">
                            <img src={Rfq01} alt="Bainub" /><br></br>
                            <div class="rfq-tag2">Metal Wire Drawers</div>
                        </Layout.Col>
                        <Layout.Col xs="12" sm="12" md="4" lg="4">
                            <img src={Rfq02} alt="Bainub" /><br></br>
                            <div class="rfq-tag2">Pet Supplies</div> 
                        </Layout.Col>
                        <Layout.Col xs="12" sm="12" md="5" lg="5">
                            <img src={Rfq03} alt="Bainub" /><br></br>
                            <div class="rfq-tag2">Cotton Heavy Duty Fabric</div>
                        </Layout.Col>
                        <Layout.Col xs="12" sm="12" md="4" lg="4">
                            <img src={Rfq04} alt="Bainub" /><br></br>
                            <div class="rfq-tag2">Coolers</div>
                        </Layout.Col>
                        <Layout.Col xs="12" sm="12" md="5" lg="5">
                            <img src={Rfq05} alt="Bainub" /><br></br>
                            <div class="rfq-tag2">Printed Packaging</div>  
                        </Layout.Col>
                    </Layout.Row>                          
                </Layout.Col>
            </Layout.Row>

                <Layout.Row>
                <Layout.Col xs="24" sm="24" md="24" lg="24" >
                <h3>And many more:</h3>              
                    <div class="rfq-tag">Printing</div>  
                    <div class="rfq-tag">Pet Clothing</div>
                    <div class="rfq-tag">Linen Bowl Covers</div>
                    <div class="rfq-tag">Banneton Baskets</div>
                    <div class="rfq-tag">Cowboy Hats</div>
                    <div class="rfq-tag">Bathroom Vanities</div>
                    <div class="rfq-tag">Bathroom Sinks</div>
                    <div class="rfq-tag">Bathroom Lighting</div>
                    <div class="rfq-tag">Bathroom Furniture</div>
                    <div class="rfq-tag">Faucets</div>
                    <div class="rfq-tag">Snack Extruded Chips</div>
                    <div class="rfq-tag">Belt Bags</div>
                    <div class="rfq-tag">Pens</div>
                    <div class="rfq-tag">Stainless Steel Flatware</div> 
                    <div class="rfq-tag">Plastic Injected Suitcase Parts</div>
                    <div class="rfq-tag">Custom Printed Boxes</div>                
                    <div class="rfq-tag">RF Plastic Welding</div>
                    <div class="rfq-tag">Marine Vinyl Rolls</div>
                    <div class="rfq-tag">Wood Toys</div>                               
                </Layout.Col>
                </Layout.Row>
            
            </Layout.Row>
            <Layout.Row>
                <Layout.Col xs="24" sm="24" md="24" lg="24" className="rfq-section" style={{ backgroundImage: `url(${RfqBackground})` }}>
                    <Layout.Row>
                        <Layout.Col xs="24" sm="24" md="12" lg="12">
                            <h2>Got a buying requirement?</h2>
                            <h1>RFQ Direct</h1>
                            <h3>A tool to easily share your requirements with verified manufacturers in Bainub ready to work with you.</h3>
                            <Link to="/login" className="el-button el-button--primary primary-button no-margin"> <b>Join to create an RFQ</b></Link><br></br><br></br>
                            <p>*To create an RFQ, you need an account. <Link to="/register">Sign up for free!</Link></p>
                        </Layout.Col>
                        <Layout.Col xs="24" sm="24" md="12" lg="12" className='text-right'>
                        <img className="RfqLaptop" src={RfqLaptop} alt="Bainub" />
                        </Layout.Col>
                    </Layout.Row>
                </Layout.Col>
            </Layout.Row>

            <Layout.Row  justify="center" align="middle" className="responsivePadding suppliersRow">
                <Layout.Col span="24">
                    <h2 className="primary-color m-t-lg">{t('home-page.new-suppliers')}</h2>
                </Layout.Col>

                <SuppliersSwiper
                    slidesPerView={4}
                    spaceBetween={16}
                    suppliers={suppliers}
                    loading={loadingSuppliers}
                    />
            </Layout.Row>


            

            <Layout.Row justify="center" align="middle" className="m-b-lg responsivePadding mt-1 AboutUsSwiper hidde-mobile">
            <Layout.Col span="24" className='text-center'>
                    <h3 className="m-t-lg">About Us</h3>
                    <h4>B2B MARKETPLACE</h4>
                </Layout.Col>
                <AboutUsSwiper
                    slidesPerView={5}
                    spaceBetween={10}
                    products={recentlyAddedProducts}
                    loading={loadingRecentlyAddedProducts}
                    />
            </Layout.Row>

            <Layout.Row>
                <Layout.Col className="responsivePadding hidde-desktop search-input-content">
                <h1 className="form-title">About Us</h1>    
            Bainub originated as an idea in 2020 and officially launched operations in 2023 in Monterrey, Mexico. 
            Our primary objective is to build an efficient network of collaboration between businesses in the North American region, 
            aiming to streamline cooperation between suppliers and buyers across borders spanning Mexico, the USA, and Canada. <br></br><br></br>
            We are proudly a North American enterprise striving to benefit our hard-working communities by creating a new supply 
            chain arrangement that contributes to lead our region into a world leading industrial power. This is why our motto is 
            “Every Business in North America Linked Together”.<br></br>
            <Layout.Row className="pt-3">
            <a href="https://www.bainub.com/about-us">Learn More</a>
            </Layout.Row>
           
                </Layout.Col>
            </Layout.Row>

            <Layout.Row className="secondary-form-mp">
                <Layout.Col>
                    <Layout.Row>
                        <Layout.Col>
                        <h1>Do you want to know more about Bainub?</h1>
                        <h3>Receive information about Bainub and support to join our trading network.</h3>
                        </Layout.Col>
                    </Layout.Row>
                    <Layout.Row>
                        <Layout.Col>
                        <RegisterSurvey className="justify-content-left" surveyTitle={t('home-page.product-survey.register-title')} formType={2} />
                        </Layout.Col>
                    </Layout.Row>
                </Layout.Col>
            </Layout.Row>
        </>
    )
}
export default Main;