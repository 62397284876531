import "./App.scss";
// import "./tailwind.min.css"
//import '@aws-amplify/ui/dist/style.css';
import React, {useEffect, Suspense} from 'react';
import { Loading } from 'element-react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import PrivateRoute from "./components/routes/PrivateRoute";
import AnonRoute from "./components/routes/AnonRoute";
import MixedRoute from "./components/routes/MixedRoute";
import MyAccountLeftMenu from "./components/MyAccountLeftMenu";
import MyStoreLeftMenu from "./components/MyStoreLeftMenu";
import BuyerToolsLeftMenu from "./components/BuyerToolsLeftMenu";
import LeftMenu from "./components/LeftMenu";
import LeftMenuFilter from "./components/LeftMenuFilter";
import Footer from "./components/Footer";
import userStore from './store/user';
import { LastLocationProvider } from 'react-router-last-location';
import AboutBainubComponent from "./components/AboutBainubComponent"
import NosotrosComponent from "./components/NosotrosComponent"



//PAGES
import Main from './pages/Main';
import Register from './pages/Register';
import Login from './pages/Login';
import RegisterConfirm from './pages/RegisterConfirm';
import ProductDetail from './pages/ProductDetail';
import SearchResult from './pages/SearchResult';
import SearchResultRedirect from './pages/SearchResultRedirect';
import SearchResultNG from "./pages/SearchResultNG";
import NotFound from './pages/NotFound';
import RecoveryPassword from './pages/RecoveryPassword';
import MyAccount from "./pages/MyAccount";
import MyStore from './pages/MyStore'
import MyStoreWizard from './pages/MyStoreWizard'
import MyStoreWizardNG from './pages/MyStoreWizardNG'
import YourStore from './pages/YourStore';
import ProductWizard from "./pages/ProductWizard";
import OrderWizard from "./pages/OrderWizard";
import MyProducts from "./pages/MyProducts";
import ProductReview from "./pages/ProductReview";
import MyChats from './pages/MyChats';
import OrderDetailView from './pages/OrderDetailView';
import ContactUs from './pages/ContactUs';
import NavigationPreferences from './pages/NavigationPreferences';
import RegistrationSuccess from './pages/RegistrationSuccess';
import HelpAndSupport from "./pages/HelpAndSupport";
import MyFavorites from "./pages/MyFavorites";
import Rfq from "./pages/Rfq";
import BuyerDashboard from "./pages/BuyerDashboard";
//PAGES UNDER CONSTRUCTION
import Dashboard from './pages/Dashboard';
import Orders from './pages/Orders';
import OrdersBuyer from './pages/OrdersBuyer';
import Calendar from './pages/Calendar';
import SalesTeam from './pages/SalesTeam';
import Analytics from './pages/Analytics';
import Visibility from './pages/Visibility';
import Services from './pages/Services';
import Default from './pages/Default';
import Cookies from './pages/Cookies';
import Empty from "./components/Empty";
//LAYOUTS
import AppLayout from "./components/layouts/AppLayout";
import NonMenuLayout from "./components/layouts/NonMenuLayout";
import CookieConsent from "react-cookie-consent";
//FORM?
import EmailForm from "./pages/EmailForm";
import BainubPrivacyPolicy from "./pages/BainubPrivacyPolicy";
import BainubTermsOfUse from "./pages/BainubTermsOfUse";
import InfoPage from "./components/layouts/InfoPage";
import UnderConstruction from "./components/UnderConstruction";

import UserGuidingScript from "./components/UserGuidingScript";

function App() {
  const {user} = userStore();  
  useEffect(() => {
    // Actualiza el título del documento usando la API del navegador
    console.log('Init');
    document.title = "Bainub";
    //readProducts();
  }, []);

  const sellerRoute = () =>{
    console.log('sellerRouter');
    console.log({user});
    if(user.type === "S"){
      return(
        <Route exact path="/loginOTRO" component={Login} />
      )
    }else{
      return(
        <Route/>
      )
    }
  }

  const handleMenuComponent = () => {
    return <HelpAndSupport menu={true} />
  }

  return (
    //TO FIX Translte without ReRender...
    

    <Suspense fallback={<Loading fullscreen={true} />}>
        <Router>
        <LastLocationProvider>
          <Switch>
              {/*MIXED ROUTES FOR LOGGED AND NON LOGGED IN USERS*/}
              <MixedRoute exact path="/" layout={AppLayout} component={Main} menuComponent={LeftMenu} showLeftMenu={true} footerComponent={Footer} showMyPreferencesWizard={true} />
              {
                /*
                  old search engine
                  <MixedRoute exact path="/search-result/:query/:tag" layout={AppLayout} component={SearchResult} showLeftMenu={true} menuComponent={LeftMenuFilter} searchType={true} footerComponent={Footer} showMyPreferencesWizard={true} />
                */
              }
              <MixedRoute exact path="/search-result/:query/:tag" layout={AppLayout} component={SearchResultRedirect} showLeftMenu={true} menuComponent={LeftMenuFilter} searchType={true} footerComponent={Footer} showMyPreferencesWizard={true} />            
              <MixedRoute exact path="/search-result-ng/:query/:tag" layout={AppLayout} component={SearchResultNG} showLeftMenu={true} menuComponent={LeftMenuFilter} searchType={true} footerComponent={Footer} showMyPreferencesWizard={true} />
              <MixedRoute exact path="/product-detail/:productid" layout={AppLayout} component={ProductDetail} showLeftMenu={false} menuComponent={React.Fragment} footerComponent={Footer} showMyPreferencesWizard={true} />
              <MixedRoute exact path="/company-store/:companyid" menuComponent={MyAccountLeftMenu} showLeftMenu={false} layout={AppLayout} component={YourStore} footerComponent={Footer} showMyPreferencesWizard={user.pendingSetupBusinessPreferences} />
              <MixedRoute exact path="/email-form" menuComponent={null} layout={NonMenuLayout} component={EmailForm} footerComponent={Footer} />
              <MixedRoute exact path="/privacy-policy" layout={AppLayout} component={BainubPrivacyPolicy} menuComponent={React.Fragment} showLeftMenu={false} footerComponent={Footer} showMyPreferencesWizard={true} />
              <MixedRoute exact path="/terms-of-use" layout={AppLayout} component={BainubTermsOfUse} menuComponent={React.Fragment} showLeftMenu={false} footerComponent={Footer} showMyPreferencesWizard={true} />
              <MixedRoute exact path="/about-us" layout={AppLayout} component={AboutBainubComponent} menuComponent={React.Fragment} showLeftMenu={false} footerComponent={Footer} showMyPreferencesWizard={true} />
              <MixedRoute exact path="/nosotros" layout={AppLayout} component={NosotrosComponent} menuComponent={React.Fragment} showLeftMenu={false} footerComponent={Footer} showMyPreferencesWizard={true} />

              
              {/*ANON ROUTES FOR NON LOGGED IN USERS*/}
              <AnonRoute exact path="/register" menuComponent={null} layout={NonMenuLayout} component={Register} footerComponent={Footer} />
              <AnonRoute exact path="/register-confirm/:user/:type" menuComponent={null} layout={NonMenuLayout} component={RegisterConfirm} footerComponent={Footer} />
              <AnonRoute exact path="/login" menuComponent={null} layout={NonMenuLayout} component={Login} footerComponent={Footer} />
              <AnonRoute exact path="/forgot-passwd" menuComponent={null} layout={NonMenuLayout} component={RecoveryPassword} footerComponent={Footer} />
              <AnonRoute exact path="/cookies-info" menuComponent={null} layout={NonMenuLayout} component={Cookies} footerComponent={Empty} />
              <MixedRoute exact path="/contact-us" menuComponent={null} layout={NonMenuLayout} component={ContactUs} footerComponent={Footer} />
              <MixedRoute exact path="/help-support" menuComponent={null} layout={NonMenuLayout} component={HelpAndSupport} footerComponent={Footer} />
              <AnonRoute exact path="/RegistrationSuccess/:type" menuComponent={null} layout={NonMenuLayout} component={RegistrationSuccess} footerComponent={Footer} />



              {/*PRIVATE ROUTES FOR LOGGED IN USERS*/}
              {/* <PrivateRoute exact path="/my-account" layout={AppLayout} showLeftMenu={true} component={MyAccount} menuComponent={MyAccountLeftMenu} footerComponent={Footer} showMyPreferencesWizard={user.pendingSetupBusinessPreferences} /> */}
              <PrivateRoute exact path="/product-review/:productid" layout={AppLayout} showLeftMenu={false} component={ProductReview} menuComponent={MyStoreLeftMenu} footerComponent={Footer} showMyPreferencesWizard={user.pendingSetupBusinessPreferences} />
              <PrivateRoute exact path="/my-store" layout={AppLayout} showLeftMenu={true} component={MyStore} menuComponent={MyStoreLeftMenu} footerComponent={Footer} showMyPreferencesWizard={user.pendingSetupBusinessPreferences} />
              <PrivateRoute exact path="/my-products" layout={AppLayout} showLeftMenu={true} component={MyProducts} menuComponent={MyStoreLeftMenu} footerComponent={Footer} showMyPreferencesWizard={user.pendingSetupBusinessPreferences} />
              <PrivateRoute exact path="/my-store-wizard/:id" menuComponent={React.Fragment} showLeftMenu={true} layout={NonMenuLayout} component={MyStoreWizard} footerComponent={Footer} showMyPreferencesWizard={user.pendingSetupBusinessPreferences} />
              <PrivateRoute exact path="/my-store-wizard-ng/:id" menuComponent={React.Fragment} showLeftMenu={true} layout={NonMenuLayout} component={MyStoreWizardNG} footerComponent={Footer} showMyPreferencesWizard={user.pendingSetupBusinessPreferences} />
              <PrivateRoute exact path="/add-product-wizard/:id" menuComponent={MyAccountLeftMenu} showLeftMenu={true} layout={NonMenuLayout} component={ProductWizard} footerComponent={Footer} showMyPreferencesWizard={user.pendingSetupBusinessPreferences} />
              <PrivateRoute exact path="/my-chats/:id" layout={AppLayout} showLeftMenu={false} component={MyChats} menuComponent={user.type == "S" ? MyStoreLeftMenu : BuyerToolsLeftMenu } footerComponent={Footer} showMyPreferencesWizard={user.pendingSetupBusinessPreferences} />
              <PrivateRoute exact path="/add-order-wizard/:id" menuComponent={MyStoreLeftMenu} showLeftMenu={true} layout={AppLayout} component={OrderWizard} footerComponent={Footer} showMyPreferencesWizard={user.pendingSetupBusinessPreferences} />
              <PrivateRoute exact path="/my-orders" layout={AppLayout} showLeftMenu={true} component={OrdersBuyer} menuComponent={MyAccountLeftMenu} footerComponent={Footer} showMyPreferencesWizard={user.pendingSetupBusinessPreferences} />
              <PrivateRoute exact path="/view-order/:id" layout={AppLayout} showLeftMenu={true} component={OrderDetailView} menuComponent={MyAccountLeftMenu} footerComponent={Footer} showMyPreferencesWizard={user.pendingSetupBusinessPreferences} />
              <PrivateRoute exact path="/my-account" layout={AppLayout} showLeftMenu={true} component={MyAccount} menuComponent={MyAccountLeftMenu} footerComponent={Footer} showMyPreferencesWizard={user.pendingSetupBusinessPreferences} />
              <PrivateRoute exact path="/help-support-menu" layout={AppLayout} showLeftMenu={true} component={handleMenuComponent} menuComponent={MyAccountLeftMenu} footerComponent={Footer} showMyPreferencesWizard={user.pendingSetupBusinessPreferences} />
              <PrivateRoute exact path="/navigation-preferences" layout={AppLayout} showLeftMenu={true} component={NavigationPreferences} menuComponent={MyAccountLeftMenu} footerComponent={Footer} showMyPreferencesWizard={user.pendingSetupBusinessPreferences} />
              <PrivateRoute exact path="/my-favorites" layout={AppLayout} showLeftMenu={true} component={MyFavorites} menuComponent={MyAccountLeftMenu} footerComponent={Footer} showMyPreferencesWizard={user.pendingSetupBusinessPreferences} />
              <PrivateRoute exact path="/rfq" layout={AppLayout} showLeftMenu={true} component={Rfq} menuComponent={MyAccountLeftMenu} footerComponent={Footer} showMyPreferencesWizard={user.pendingSetupBusinessPreferences} />

              <PrivateRoute exact path="/tools/dashboard" layout={AppLayout} showLeftMenu={true} component={BuyerDashboard} menuComponent={BuyerToolsLeftMenu} footerComponent={Footer} showMyPreferencesWizard={user.pendingSetupBusinessPreferences} />
              <PrivateRoute exact path="/tools/rfq" layout={AppLayout} showLeftMenu={true} component={Rfq} menuComponent={BuyerToolsLeftMenu} footerComponent={Footer} showMyPreferencesWizard={user.pendingSetupBusinessPreferences} />
              <PrivateRoute exact path="/tools/my-orders" layout={AppLayout} showLeftMenu={true} component={OrdersBuyer} menuComponent={BuyerToolsLeftMenu} footerComponent={Footer} showMyPreferencesWizard={user.pendingSetupBusinessPreferences} />
              <PrivateRoute exact path="/tools/favorites" layout={AppLayout} showLeftMenu={true} component={MyFavorites} menuComponent={BuyerToolsLeftMenu} footerComponent={Footer} showMyPreferencesWizard={user.pendingSetupBusinessPreferences} />

              {/* UNDER CONSTRUCTION */}
              <AnonRoute exact path="/learn-more" menuComponent={null} layout={NonMenuLayout} component={Default} footerComponent={Footer} />
              <AnonRoute exact path="/help-and-support" menuComponent={null} layout={NonMenuLayout} component={Default} footerComponent={Footer} />
              <PrivateRoute exact path="/dashboard" layout={AppLayout} showLeftMenu={true} component={Dashboard} menuComponent={MyStoreLeftMenu} footerComponent={Footer} showMyPreferencesWizard={user.pendingSetupBusinessPreferences} />
              <PrivateRoute exact path="/orders" layout={AppLayout} showLeftMenu={true} component={Orders} menuComponent={MyStoreLeftMenu} footerComponent={Footer} showMyPreferencesWizard={user.pendingSetupBusinessPreferences} />
              <PrivateRoute exact path="/calendar" layout={AppLayout} showLeftMenu={true} component={Calendar} menuComponent={MyStoreLeftMenu} footerComponent={Footer} showMyPreferencesWizard={user.pendingSetupBusinessPreferences} />
              <PrivateRoute exact path="/sales-team" layout={AppLayout} showLeftMenu={true} component={SalesTeam} menuComponent={MyStoreLeftMenu} footerComponent={Footer} showMyPreferencesWizard={user.pendingSetupBusinessPreferences} />
              <PrivateRoute exact path="/analytics" layout={AppLayout} showLeftMenu={true} component={Analytics} menuComponent={MyStoreLeftMenu} footerComponent={Footer} showMyPreferencesWizard={user.pendingSetupBusinessPreferences} />
              <PrivateRoute exact path="/visibility" layout={AppLayout} showLeftMenu={true} component={Visibility} menuComponent={MyStoreLeftMenu} footerComponent={Footer} showMyPreferencesWizard={user.pendingSetupBusinessPreferences} />
              <PrivateRoute exact path="/services" layout={AppLayout} showLeftMenu={true} component={Services} menuComponent={MyStoreLeftMenu} footerComponent={Footer} showMyPreferencesWizard={user.pendingSetupBusinessPreferences} />
              {/* UNDER CONSTRUCTION - MY ACCOUNT */}
              
              <PrivateRoute exact path="/payment-detail" layout={AppLayout} showLeftMenu={true} component={Default} menuComponent={MyAccountLeftMenu} footerComponent={Footer} showMyPreferencesWizard={user.pendingSetupBusinessPreferences} />
              <PrivateRoute exact path="/history-logs" layout={AppLayout} showLeftMenu={true} component={Default} menuComponent={MyAccountLeftMenu} footerComponent={Footer} showMyPreferencesWizard={user.pendingSetupBusinessPreferences} />
              <PrivateRoute exact path="/site-configuration" layout={AppLayout} showLeftMenu={true} component={Default} menuComponent={MyAccountLeftMenu} footerComponent={Footer} showMyPreferencesWizard={user.pendingSetupBusinessPreferences} />
              <PrivateRoute exact path="/resources" layout={AppLayout} showLeftMenu={true} component={Default} menuComponent={MyAccountLeftMenu} footerComponent={Footer} showMyPreferencesWizard={user.pendingSetupBusinessPreferences} />
              {/* NOT FOUND */}
              <MixedRoute layout={AppLayout} component={NotFound} menuComponent={LeftMenu} showLeftMenu={false} footerComponent={Footer} showMyPreferencesWizard={false} />
              { sellerRoute() }
          </Switch>
        </LastLocationProvider>
      </Router>
      <UserGuidingScript />
      <CookieConsent 
          style={{ 
            background: "#e1e1e1" ,
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
          buttonStyle={{ 
            color: "#323232", 
            backgroundColor: "#FF6D73", 
            fontSize: "16px",
            borderRadius: "20px",
            marginLeft: "20px",
            marginRight: "20px",
            padding: "10px 15px",
          }} 
          expires={14} >
          This site uses strictly necessary cookies to provide you with a more personalized user experience. <a target="_blank" href="/cookies-info">Learn More</a>
      </CookieConsent>
    </Suspense>
      
  );
}
export default App;