const USE_PRD_EMAIL = true;
const FROM_EMAIL= 'noreply@bainub.com';
const SEARCH_URL = 'https://files.bainub.com/search-products';
const GET_PRODUTCS_URL = 'https://files.bainub.com/get-products';
const FILES_URL = 'https://files.bainub.com/s3UploadFile';
const GET_SIGNED_URL = 'https://files.bainub.com/getSignedUrl';
const GET_SIGNED_B64_URL = 'https://files.bainub.com/getSignedUrlB64';
const NEW_USER_NOTIFICATION = 'https://files.bainub.com/pgFunctions/newUserNotifications'; 
const GET_SYSTEM_CONFIG_URL = 'https://files.bainub.com/pgFunctions/getSystemConfigurationData/';

export { USE_PRD_EMAIL, FROM_EMAIL, FILES_URL, GET_SIGNED_URL, GET_SIGNED_B64_URL, SEARCH_URL, GET_PRODUTCS_URL, NEW_USER_NOTIFICATION, GET_SYSTEM_CONFIG_URL }